@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');
html {
  height: 100%;
}
#root, #root>div {
  height: 100%;
}
body {
  margin: 0;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Varela Round', sans-serif;
}